import React, { useContext, useState } from "react";
import { ChevronRight } from "react-bootstrap-icons";
import toast from "react-hot-toast";
import { auth } from "../../../lib/firebase/firebase";
import ConfirmPurchase from "./ConfirmPurchase";
import { buyProduct } from "../../../api/mailerfind/stripe";
import { UserContext } from "../../../contexts/UserContext";
import PurchasedModal from "./PurchasedModal";

const product = {
  id: 5,
  name: "Technical Setup",
  subtitle: "Delegate the technical setup of your cold emails to our team.",
  description:
    "At Mailerfind, we understand that scaling email campaigns can be challenging, especially when managing multiple accounts, domains, and ensuring your emails land in the inbox rather than the spam folder.\nThat’s why we’ve developed our Technical Setup Service—a solution designed to handle all the complex and tedious technical tasks for you. This allows you to focus on what truly matters: closing deals and growing your business.\nIn the video above, we'll guide you through using this powerful new feature.",
  tags: ["Productivity", "Popular", "Featured", "Essentials"],
  developer: {
    name: "Mailerfind",
    icon: "",
  },
  availableFor: ["free", "unlimited", "starter", "enterprise"],
  features: [
    "Email Account Configuration",
    "Domain Reputation Protection",
    "Unified Inbox Creation",
    "Email Account Warm-Up",
    "Connecting Email Accounts to Mailerfind",
  ],
  plans: [
    {
      name: "Technical Setup",
      description: "",
      slug: "default",
      price: 250,
      currency: "eur",
      interval: "one_time",
      prices: {
        production: "price_1PrMc6GhTxQgzhwgHqOUVoLt",
        development: "price_1PrMc6GhTxQgzhwgHqOUVoLt",
      },
    },
  ],
};

function TechSetup() {
  const [loading, setLoading] = useState(false);
  const plan = product.plans[0];
  const [paymentIsLoading, setPaymentIsLoading] = useState(false);
  const [showConfirmPurchaseModal, setShowConfirmPurchaseModal] = useState(false);
  const [showPurchasedModal, setShowPurchasedModal] = useState(false);

  const { forceRefreshUser } = useContext(UserContext) as any;

  const handleConfirmPurchase = async (plan: any) => {
    setShowConfirmPurchaseModal(true);
  };

  const handlePurchasePlan = async () => {
    let toastId = null;
    try {
      toastId = toast.loading("Processing payment...");
      setPaymentIsLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const mode = process.env.NODE_ENV === "production" ? "production" : "development";
      await buyProduct(product?.plans[0]?.prices[mode], jwt);
      await forceRefreshUser();
      toast.success(`Success!`, { id: toastId });
      setShowConfirmPurchaseModal(false);
      setShowPurchasedModal(true);
    } catch (err: any) {
      toast.error(err.message || "An error occurred", { id: toastId });
    } finally {
      setPaymentIsLoading(false);
    }
  };

  return (
    <div className="product-page mt-5">
      <div className="container content-space-t-2 content-space-b-lg-2">
        <div className="row">
          <div className="col-md-4 col-lg-3 mb-9 mb-md-0">
            <div className="card card-body py-6">
              <div className="text-center mb-4">
                <img className="avatar avatar-xl p-2" src="/assets/vendor/duotone-icons/cod/cod009.svg" alt="Logo" />
              </div>
              <div className="d-grid mb-7">
                <a className="btn btn-block btn-primary transition-3d-hover" href="#pricing">
                  Purchase
                </a>
              </div>
              <div className="mb-7">
                <h2 className="h4">Tags</h2>
                {product.tags.map((tag, index) => {
                  return (
                    <a className="btn btn-soft-secondary btn-xs mr-1 mb-2" href="#" key={index}>
                      {tag}
                    </a>
                  );
                })}
              </div>
              <div className="d-none d-md-block">
                <h4>Developer</h4>
                <a className="d-flex align-items-center" href="#">
                  <div className="flex-shrink-0">
                    <img className="avatar avatar-sm" src="/appicon.png" alt="Logo" />
                  </div>
                  <div className="flex-grow-1 ml-1">
                    <span className="link-secondary align-middle">{product.developer.name}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* End Col */}
          <div className="col-md-8 col-lg-9 product-column-divider column-divider-md">
            <div className="card card-body p-6">
              <div className="mb-4">
                <h1>
                  {product.name}
                  <img
                    className="avatar avatar-xs mb-1 ml-2"
                    src="/assets/svg/illustrations/top-vendor.svg"
                    alt="Badge"
                    title="Top Vendor"
                  />
                </h1>
                <p className="mb-0">{product.subtitle}</p>
              </div>

              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="featuresOne" role="tabpanel" aria-labelledby="featuresOne-tab">
                  <div className="mb-3">
                    <h4 className="mb-3">Demo</h4>
                    <div style={{ position: "relative", paddingBottom: "64.92335437330928%", height: 0 }}>
                      <iframe
                        src="https://www.loom.com/embed/372b66d94bfa49719f74a1a78c00cfc6?sid=94cbf63c-c540-44a4-9c07-7566ebdbc31a"
                        title="Demo"
                        style={{ position: "absolute", border: 0, top: 0, left: 0, width: "100%", height: "100%", borderRadius: 15 }}
                      />
                    </div>
                  </div>

                  <h4 className="mb-3">Description</h4>
                  <p>
                    {product.description.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
                  </p>

                  <p>What Does Our Technical Setup Service Include?</p>
                  <ul className="list-unstyled list-pointer mb-5">
                    {product.features.map((feature, index) => {
                      return (
                        <li key={index}>
                          <ChevronRight size="12" className="align-middle mb-1 mx-2" />
                          {feature}
                        </li>
                      );
                    })}
                  </ul>

                  <p>
                    Once you place your order, a member of our team will reach out to you via email shortly. They will guide you through the
                    entire process and serve as your main point of contact.
                  </p>

                  <p>
                    It typically takes 24 to 48 hours for us to complete the setup. Your point of contact will keep you updated on the
                    progress.
                  </p>

                  <p>
                    This service is designed to make your life significantly easier by eliminating the complexities and time-consuming tasks
                    involved in setting up email campaigns. If you have any questions or need further assistance, feel free to contact our
                    support team at <a href="mailto:support@mailerfind.com">support@mailerfind.com</a>.
                  </p>

                  <Pricing plans={product.plans} purchasePlan={handleConfirmPurchase} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmPurchase
        show={showConfirmPurchaseModal}
        setShow={setShowConfirmPurchaseModal}
        handlePurchase={handlePurchasePlan}
        loading={loading}
        plan={plan}
        paymentIsLoading={paymentIsLoading}
      />
      <PurchasedModal show={showPurchasedModal} setShow={setShowPurchasedModal} />
    </div>
  );
}

function Pricing({ plans, purchasePlan }: any) {
  return (
    <div className="tab-pane fade active show" id="pricing" role="tabpanel" aria-labelledby="featuresThree-tab">
      <h4 className="mb-3">Pricing plans</h4>
      <div className="d-grid gap-2 mb-5">
        {plans.map((plan: any, index: number) => {
          return <Price plan={plan} purchasePlan={purchasePlan} key={index} />;
        })}
      </div>
    </div>
  );
}

function Price({ plan, purchasePlan }: any) {
  return (
    <div className="card card-sm card-bordered shadow-none mb-3">
      <div className="card-body">
        <div className="row align-items-sm-center">
          <div className="col">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <span className="svg-icon text-center text-primary" style={{ minWidth: "3rem" }}>
                  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0)">
                      <path
                        opacity="0.85"
                        d="M9.6 20.2C14.9019 20.2 19.2 15.9019 19.2 10.6C19.2 5.29807 14.9019 1 9.6 1C4.29807 1 0 5.29807 0 10.6C0 15.9019 4.29807 20.2 9.6 20.2Z"
                        fill="#377dff"
                      />

                      <path
                        opacity="0.85"
                        d="M22.4 20.2C27.702 20.2 32 15.9019 32 10.6C32 5.29807 27.702 1 22.4 1C17.0981 1 12.8 5.29807 12.8 10.6C12.8 15.9019 17.0981 20.2 22.4 20.2Z"
                        fill="#377dff"
                      />

                      <path
                        opacity="0.85"
                        d="M16.2001 31C21.502 31 25.8001 26.7019 25.8001 21.4C25.8001 16.0981 21.502 11.8 16.2001 11.8C10.8982 11.8 6.6001 16.0981 6.6001 21.4C6.6001 26.7019 10.8982 31 16.2001 31Z"
                        fill="#377dff"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width={32} height={32} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
              <div className="flex-grow-1 ml-4">
                <h4 className="card-title mb-0">{plan.name}</h4>
              </div>
            </div>
          </div>
          {/* End Col */}
          <div className="col-sm-7 col-md-5">
            <p className="card-text small">{plan.description}</p>
          </div>
          {/* End Col */}
          <div className="col-12 col-lg-3 mt-3 mt-lg-0">
            <div className="d-grid">
              <button className="btn btn-outline-primary w-100" onClick={() => purchasePlan(plan)}>
                {new Intl.NumberFormat("es-ES", {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 3,
                }).format(plan.price)}{" "}
              </button>
            </div>
          </div>
          {/* End Col */}
        </div>
        {/* End Row */}
      </div>
    </div>
  );
}

export default TechSetup;
