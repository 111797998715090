import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { EnvelopeOpen, Link45deg, Send, StarFill } from "react-bootstrap-icons";
import { FRONTEND_URL } from "../../utils/constants";
import { auth } from "../../lib/firebase/firebase";
import toast from "react-hot-toast";
import { createInvitation } from "../../api/mailerfind/invitations";
import PremiumModal from "../../components/PremiumModal";
import { useTranslation } from "react-i18next";

function OutOfCreditsModal({ show, setShow }: any) {
  const { t } = useTranslation("OutOfCreditsModal");
  const handleClose = () => setShow(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const handleCopyInviteLink = () => {
    navigator.clipboard.writeText(`${FRONTEND_URL}/register?plan=free&ref=${auth.currentUser?.uid}`);
    toast.success(t("linkCopied"));
  };

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (!email) return toast.error(t("noEmailError"));
    let toastId = null;
    try {
      setLoading(true);
      toastId = toast.loading(t("sendingInvitation"));
      const jwt = auth.currentUser ? await auth.currentUser.getIdToken() : "";
      await createInvitation(email.trim(), jwt);
      setEmail("");
      toast.success(`${t("invitationSent")} ${email}`, { id: toastId });
    } catch (err: any) {
      toast.error(err.message, { id: toastId });
    } finally {
      setLoading(false);
    }
  };

  const handleUpgradePlan = () => {
    setShow(false);
    setTimeout(() => {
      setShowPremiumModal(true);
    }, 500);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Body className="p-8">
          <div className="row">
            <div className="col-lg-7 my-auto">
              <h1>{t("outOfCreditsTitle")}</h1>
              <p>{t("outOfCreditsDescription")}</p>
              <div className="mb-3">
                <label htmlFor="inputGroupMergeEmail" className="form-label">
                  {t("emailLabel")}
                </label>
                <div className="input-group input-group-merge">
                  <div className="input-group-prepend input-group-text" id="inputGroupMergeEmailAddOn">
                    <EnvelopeOpen />
                  </div>
                  <input
                    type="email"
                    value={email}
                    onChange={handleChange}
                    className="form-control"
                    id="inputGroupMergeEmail"
                    placeholder={t("emailPlaceholder")}
                    aria-label={t("emailPlaceholder")}
                    aria-describedby="inputGroupMergeEmailAddOn"
                  />
                </div>
              </div>
              <div className="d-flex">
                <button className="btn btn-white mr-2 w-100" onClick={handleCopyInviteLink}>
                  <Link45deg size={20} className="mr-2" />
                  <span className="align-middle">{t("copyLinkButton")}</span>
                </button>
                <button className="btn btn-primary w-100" onClick={handleSubmit} disabled={loading}>
                  {t("sendInvitationButton")}
                  <Send size={16} className="ml-2" />
                </button>
              </div>
              <div className="text-center mt-2">
                <div onClick={handleUpgradePlan} className="text-secondary" style={{ cursor: "pointer" }}>
                  <StarFill className="text-white bg-primary mr-1" style={{ padding: "4px 1px", borderRadius: 99 }} />
                  <small>
                    <u className="m-0 align-middle">{t("upgradePlan")}</u>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-lg-block d-none text-center my-auto">
              <img src="/assets/svg/illustrations/oc-referrals.svg" className="img-fluid" alt={t("referralsImageAlt")} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <PremiumModal show={showPremiumModal} setShow={setShowPremiumModal} title={t("updatePlanTitle")} />
    </>
  );
}

export default OutOfCreditsModal;