import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { getAllAnalyses as getAllLocalAnalyses } from "../../lib/daos/analysisDAO";
import { Plus } from "react-bootstrap-icons";
import { toast } from "react-hot-toast";
import { getAllAnalysis as getAllCloudAnalyses } from "../../lib/firebase/analysis";
import { UserContext } from "../../contexts/UserContext";
import Stats from "./Stats";
import AnalysisLoop from "./AnalysisLoop";
import { ProjectContext } from "../../contexts/ProjectContext";
import useAnalysis from "../../hooks/useAnalysis";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

function AnalysisManager() {
  const { t, ready } = useTranslation("AnalysisManager.index");
  const [analyses, setAnalyses] = useState([]) as any;
  const [loading, setLoading] = useState(true);
  const [analysesCount, setAnalysesCount] = useState(0);
  const { createAnalysis, loading: analysisLoading } = useAnalysis(null);

  const { user } = useContext(UserContext) as any;
  const { project } = useContext(ProjectContext) as any;

  const fetchAllAnalyses = async (showLoading = true) => {
    try {
      showLoading && setLoading(true);
      const localAnalyses = await getAllLocalAnalyses(undefined, undefined, user?.firebaseUser?.uid, null, project?.id || null);
      const cloudAnalyses = await getAllCloudAnalyses(user?.firebaseUser?.uid, null, project?.id);
      cloudAnalyses.forEach((ca) => (ca.createdAt = ca.createdAt.toDate()));
      const analyses = [...localAnalyses, ...cloudAnalyses];
      analyses.sort((a: any, b: any) => b.createdAt - a.createdAt);
      setAnalyses(analyses);
      setAnalysesCount(localAnalyses.length + cloudAnalyses.length);
    } catch (err: any) {
      toast.error(t("notificationErrorFetching") || err.message);
    } finally {
      showLoading && setLoading(false);
    }
  };

  useEffect(() => {
    if (!user.firebaseUser) return;
    fetchAllAnalyses();
  }, [user, project]);

  if (!loading && analyses.length === 0)
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "80vh" }}>
        <div className="col-sm-7 col-md-5 text-center">
          <img className="img-fluid mb-5" style={{ maxWidth: 350 }} src="/assets/svg/illustrations/yelling.svg" alt="Yelling" />
          <h1>{t("welcomeTitle")}</h1>
          <p>{t("welcomeMessage")}</p>
          <button className="btn btn-primary" onClick={createAnalysis} disabled={analysisLoading}>
            <Plus size="20" className="mr-1" /> <span className="align-middle">{t("createFirstAnalysisButton")}</span>
          </button>
        </div>
      </div>
    );

  return (
    <div className="">
      {/* Page Header */}
      <div className="page-header">
        <div className="row align-items-end">
          <div className="col-sm mb-2 mb-sm-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-no-gutter">
                <li className="breadcrumb-item">
                  <Link className="breadcrumb-link" to="/">
                    {t("breadcrumbDashboard")}
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {ready ? t("breadcrumbAnalysisManager") : <Skeleton width={200} />}
                </li>
              </ol>
            </nav>
            <h1 className="page-header-title">{t("pageTitle")}</h1>
          </div>
          <div className="col-sm-auto">
            <button className="btn btn-primary" onClick={createAnalysis} disabled={analysisLoading}>
              <Plus size="20" className="mr-1" />
              <span className="align-middle">{t("newAnalysisButton")}</span>
            </button>
          </div>
        </div>
      </div>
      <Stats analyses={analyses} analysesCount={analysesCount} loading={loading} />
      <AnalysisLoop />
    </div>
  );
}

export default AnalysisManager;
