import makeRequest from "../client";
import { BACKEND_URL } from "../../utils/constants";

export const renewAccessToken = async (senderId: string, jwt: string): Promise<any> => {
  const body = {
    senderId,
  };

  const data = {
    url: `${BACKEND_URL}/v1/auth/google/refresh-access-token`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body,
  };

  try {
    const response = await makeRequest(data);
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getZendeskToken = async (jwt: string): Promise<any> => {
  const data = {
    url: `${BACKEND_URL}/v1/auth/zendesk`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  };

  try {
    const response = await makeRequest(data);
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
