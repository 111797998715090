import { useTranslation } from "react-i18next";
import React from "react";

const lngs = {
  en: { nativeName: "English (US)" },
  es: { nativeName: "Español" },
} as any;

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  return (
    <>
      <div className="hs-unfold">
        <div
          className="js-hs-unfold-invoker hs-mega-menu-invoker"
          id="withMegaMenuDropdownEg"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <a className="dropdown-item rounded py-3" href="#">
            <img
              className="avatar avatar-xss avatar-circle mr-2"
              src={`assets/vendor/flag-icon-css/flags/1x1/${i18n.resolvedLanguage}.svg`}
              alt="Flag"
            />
            <span className="text-truncate pr-2 align-middle" title="English">
              {lngs[i18n.resolvedLanguage || "es"].nativeName}
            </span>
          </a>
        </div>
        {/* Dropdown */}
        <div className="dropdown">
          <div className="dropdown-menu py-2 px-3" style={{ minWidth: 250, transform: "translate3d(0px,-20px,0px)" }}>
            {Object.keys(lngs).map((lng) => (
              <div className="dropdown-item-text p-1" key={lng}>
                <div className="media align-items-center">
                  <div className="dropdown-item p-3" onClick={() => i18n.changeLanguage(lng)} style={{ cursor: "pointer" }}>
                    <img
                      className="avatar avatar-xss avatar-circle mr-2"
                      src={`assets/vendor/flag-icon-css/flags/1x1/${lng}.svg`}
                      alt="Flag"
                    />
                    <span
                      className="text-truncate pr-2"
                      title="English"
                      style={{ fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal" }}
                    >
                      {lngs[lng].nativeName}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Dropdown */}
      </div>
    </>
  );
}

export default LanguageSwitcher;
