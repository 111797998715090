import React, { useContext, useState } from "react";
import Wrapper from "./Wrapper";
import SearchBar from "../../components/SearchBar";
import { auth } from "../../lib/firebase/firebase";
import { getUserInfo } from "../../api/mailerfind/users";
import toast from "react-hot-toast";
import { UserContext } from "../../contexts/UserContext";
import { createAnalysis as createLocalAnalysis } from "../../lib/daos/analysisDAO";
import { createAnalysis as createCloudAnalysis } from "../../lib/firebase/analysis";
import { MODE } from "../../utils/constants";
import { Shuffle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function Step1({ setAnalysisId }: any) {
  const { t } = useTranslation("Wizard.Step1");
  const CURRENT_STEP = 1;
  const usernames = [
    "javier.elices",
    "euge.oller",
    "romualdfons",
    "jose_elias_navarro",
    "xaviesqueriguela",
    "engelvoelkers.espana",
    "herbalife.espana",
    "cryptospainoficial",
    "tengounplanpodcast",
    "kikejurado_",
    "pau_anto",
    "christian_bresser",
    "enrique.vv",
    "sergiocripto",
    "andorralovers",
    "kike_gtr",
    "pabloandre92",
    "lucenathor",
    "decathlon_espana",
    "ikeaspain",
  ];
  const randomIndex = Math.floor(Math.random() * usernames.length);
  const [defaultUsername, setDefaultUsername] = useState(usernames[randomIndex]);
  const [selectedUser, setSelectedUser] = useState(null) as any;
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext) as any;

  const handleRotateUsername = () => {
    const randomIndex = Math.floor(Math.random() * usernames.length);
    setDefaultUsername(usernames[randomIndex]);
  };

  const handleAccountSelect = async (e: any) => {
    try {
      setDefaultUsername(e.value);
      setLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const user = await getUserInfo(e.value, jwt);
      setSelectedUser(user);
      return user;
    } catch (err: any) {
      toast.error(err.message || t("errorOccurred"));
    } finally {
      setLoading(false);
    }
  };

  const handleNextStep = async () => {
    try {
      setLoading(true);
      const analysisId = await handleCreateAnalysis();
      await setAnalysisId(analysisId);
    } catch (err: any) {
      toast.error(t("errorCreatingAnalysis"));
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAnalysis = async () => {
    let mutableSelectedUser = selectedUser;

    if (!selectedUser) {
      const jwt = await auth.currentUser!.getIdToken();
      mutableSelectedUser = await getUserInfo(defaultUsername, jwt);
    }

    const analysisName = t("followersOf", { username: mutableSelectedUser.username });

    let analysisId = "";
    if (user?.firebaseUser?.analysis?.cloudEnabled === true) {
      analysisId = await createCloudAnalysis(user?.firebaseUser?.uid, analysisName, {
        selectedItem: mutableSelectedUser,
        mode: MODE.FOLLOWERS,
      });
    } else {
      analysisId = await createLocalAnalysis(analysisName, user?.firebaseUser?.uid, {
        selectedItem: mutableSelectedUser,
        mode: MODE.FOLLOWERS,
      });
    }

    return analysisId;
  };

  return (
    <Wrapper
      step={CURRENT_STEP}
      title={t("title")}
      subtitle={t("subtitle")}
      beforeNext={handleNextStep}
      loading={loading}
    >
      <p className="mb-2">{t("whichAccount")}</p>
      <div className="d-flex mb-6">
        <div className="col-1 px-lg-2 px-0 my-auto">
          <img src="/assets/img/logos/instagram.png" alt="Instagram Logo" className="img-fluid" />
        </div>
        <div className="col-10 my-auto">
          <SearchBar onSelect={handleAccountSelect} selectedAccount={{ username: defaultUsername }} />
        </div>
        <div className="col p-0 my-auto text-right">
          <Shuffle
            onClick={handleRotateUsername}
            className="btn-soft-primary rounded-circle btn"
            style={{ width: 37, height: 37, padding: 10 }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

export default Step1;