import React, { useContext, useEffect, useState } from "react";
import { CloudDownload } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import Error from "../Error";
import toast from "react-hot-toast";
import { getAnalysisById } from "../../lib/firebase/analysis";
import { UserContext } from "../../contexts/UserContext";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";

function Download() {
  const { analysisId } = useParams();
  const { user } = useContext(UserContext) as any;
  const { t } = useTranslation("Download");

  const [loading, setLoading] = useState(true);
  const [analysis, setAnalysis] = useState(null) as any;

  const url = window.location.search.replace("?url=", "");

  const fetchData = async () => {
    if (!analysisId) return;
    try {
      setLoading(true);
      const analysis = await getAnalysisById(user.firebaseUser.uid, analysisId);
      console.log(analysis);
      setAnalysis(analysis);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenInGoogleSheets = () => {
    if (!url) return;
    const appUrl = "https://script.google.com/macros/s/AKfycbxFd7jz6drvknn9e4ecarU28HWQaW8rJ11Umr2DdJtM3wZlLBt-CoYFnjM1WEOHkVfm/exec";
    const csvUrl = url;
    const fullUrl = appUrl + "?url=" + encodeURIComponent(csvUrl);
    window.open(fullUrl);
  };

  if (loading) return <Loading />;
  if (!url) return <Error />;
  if (!loading && !analysis) return <Error />;

  return (
    <div className="bg-light vh-100 d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="card mx-auto" style={{ maxWidth: "30rem" }}>
          <div className="card-header">
            <h5 className="card-title">{t("pageTitle")}</h5>
          </div>
          <div className="card-body">
            <p>{t("congratulationsMessage")}</p>
            <div className="bg-dark text-white p-4 rounded">
              <div className="row">
                <div className="col-2">
                  <img className="avatar avatar mr-2" src="/assets/svg/components/placeholder-csv-format.svg" alt={t("csvAltText")} />
                </div>
                <div className="col-10">
                  <h5 className="text-white">{analysis?.name}.csv</h5>
                  <p>
                    <b>{t("idLabel")}:</b> {analysis.id}
                  </p>
                </div>
              </div>
              <a className="btn btn-primary w-100" href={url}>
                <CloudDownload className="mr-1" /> <b>{t("downloadButtonText")}</b>
              </a>
              {/*<p className="text-center m-0" style={{ cursor: "pointer" }} onClick={handleOpenInGoogleSheets}>
                <u>
                  <img src="/assets/img/gsheets.png" className="img-fluid mr-1 mb-1" style={{ width: 16 }} alt="Google Sheets Icon" />
                  <span className="align-middle">Import to Google Sheets</span> <BoxArrowUpRight size={10} className="mb-1" />
                </u>
            </p>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;