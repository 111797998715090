import React, { useContext, useEffect, useRef } from "react";
import { UserContext } from "../contexts/UserContext";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ProductTour() {
  const { user, setUser } = useContext(UserContext) as any;
  const driverObj = useRef(null) as any;
  const navigate = useNavigate();
  const location = useLocation();
  const { t, ready } = useTranslation("Component.ProductTour");

  useEffect(() => {
    // In order to to start the product tour user must:
    // 1. Not have completed the product tour previously
    // 2. Have completed the wizard
    const tourImplementationDate = new Date("April 25, 2024 00:00:00 GMT+0000").getTime();
    const userCreationDate = user?.firebaseUser?.metadata?.createdAt;

    if (
      !user?.firebaseUser?.tour?.completed &&
      user?.firebaseUser?.wizard?.completed &&
      Number(tourImplementationDate) <= userCreationDate
    ) {
      startTour();
    }
  }, [user]);

  const ensureElement = (selector: string, callback: any) => {
    const checkExist = setInterval(() => {
      if (document.querySelector(selector)) {
        clearInterval(checkExist);
        callback();
      }
    }, 100); // chequea cada 100 ms
  };

  useEffect(() => {
    if (driverObj.current || !ready) return;
    driverObj.current = driver({
      doneBtnText: t("doneBtnText"),
      nextBtnText: t("nextBtnText"),
      prevBtnText: t("prevBtnText"),
      showProgress: true,
      allowClose: false,
      disableActiveInteraction: true,
      progressText: t("progressText"),
      onDestroyStarted: () => {
        if (!driverObj.current.hasNextStep()) {
          setUser({
            tour: {
              completed: true,
              completedAt: new Date(),
            },
          });
          driverObj.current.destroy();
        }
      },
      steps: [
        {
          popover: {
            title: t("steps.welcome.title"),
            description: t("steps.welcome.description"),
          },
        },
        {
          element: "#nav-emails",
          popover: {
            title: t("steps.emailSender.title"),
            description: t("steps.emailSender.description"),
          },
        },
        {
          element: "#emails-list-card",
          popover: {
            title: t("steps.emailCampaign.title"),
            description: t("steps.emailCampaign.description"),
          },
        },
        {
          element: "#new-email-btn",
          popover: {
            title: t("steps.newEmail.title"),
            description: t("steps.newEmail.description"),
            onNextClick: () => {
              navigate(`/emails/${user?.firebaseUser?.wizard?.emailId ? `edit/${user?.firebaseUser?.wizard?.emailId}` : "new"}`);
              ensureElement("#email-settings-card", () => driverObj.current.moveNext());
            },
          },
        },
        {
          element: "#email-title-input",
          popover: {
            title: t("steps.emailTitle.title"),
            description: t("steps.emailTitle.description"),
            onPrevClick: () => {
              navigate("/emails");
              ensureElement("#new-email-btn", () => driverObj.current.movePrevious());
            },
          },
        },
        {
          element: "#email-analysis-input",
          popover: {
            title: t("steps.emailAnalysis.title"),
            description: t("steps.emailAnalysis.description"),
          },
        },
        {
          element: "#email-sender-input",
          popover: {
            title: t("steps.emailSenderInput.title"),
            description: t("steps.emailSenderInput.description"),
          },
        },
        {
          element: "#email-subject-card",
          popover: {
            title: t("steps.emailSubject.title"),
            description: t("steps.emailSubject.description"),
          },
        },
        {
          element: "#templates-dropdown",
          popover: {
            title: t("steps.emailTemplates.title"),
            description: t("steps.emailTemplates.description"),
          },
        },
        {
          element: "#email-body-card",
          popover: {
            title: t("steps.emailBody.title"),
            description: t("steps.emailBody.description"),
          },
        },
        {
          element: "#send-email-example-btn",
          popover: {
            title: t("steps.sendTestEmail.title"),
            description: t("steps.sendTestEmail.description"),
          },
        },
        {
          element: "#send-email-btn",
          popover: {
            title: t("steps.sendEmail.title"),
            description: t("steps.sendEmail.description"),
          },
        },
        {
          element: "#nav-overview",
          popover: {
            title: t("steps.collectContacts.title"),
            description: t("steps.collectContacts.description"),
          },
        },
        {
          popover: {
            title: t("steps.end.title"),
            description: t("steps.end.description"),
          },
        },
      ],
    });
  }, [navigate, ready]);

  const startTour = () => {
    // Verifica si la ruta actual ya es /emails
    if (location.pathname !== "/emails") {
      navigate("/emails");
    }
    if (!driverObj.current) return;
    driverObj.current.drive();
  };

  return <div></div>;
}

export default ProductTour;
