import { signOut as firebaseSignOut } from "firebase/auth";
import { auth } from "./firebase";
import { logout as zendeskLogout } from "../zendesk";

const signOut = () => {
  firebaseSignOut(auth)
    .then(() => {
      console.log("log out successful");
      zendeskLogout();
    })
    .catch((error: any) => {
      // An error happened.
      console.log("error logging out");
      console.error(error);
    });
};

export { signOut };
