import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { getCheckoutSessionURL, loadFreePlan } from "../api/mailerfind/stripe";
import { PRICES } from "../utils/constants";
import { auth } from "../lib/firebase/firebase";
import { UserContext } from "../contexts/UserContext";

const useCheckout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useContext(UserContext) as any;

  const redirectToCheckout = async () => {
    setIsLoading(true);
    setError("");

    if (!user.firebaseUser) {
      setIsLoading(false);
      setError("User not authenticated");
      return;
    }

    const plan = localStorage.getItem("plan") || "starter"; // starter, business, enterprise, unlimited
    const currency = localStorage.getItem("currency") || "eur"; // eur or usd
    const coupon = localStorage.getItem("coupon") as any;
    const billingPeriod = localStorage.getItem("billingPeriod") || "month"; // month or year
    const forcedPrice = localStorage.getItem("forcedPrice");
    const enviroment = process.env.NODE_ENV as any;
    const trial = localStorage.getItem("trial") === "true";

    if (plan === "free") {
      try {
        const jwt = await auth.currentUser!.getIdToken();
        const sub = await loadFreePlan(jwt);
        console.log(sub);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        window.location.href = "/onboarding/step/1";
      } catch (err: any) {
        toast.error(err.message);
        setIsLoading(false);
      }
    }

    let price = forcedPrice || PRICES[plan][billingPeriod][currency][enviroment];
    const lineItems = price.split(",").map((item: any) => {
      return {
        price: item,
        quantity: 1,
      };
    });
    try {
      const jwt = await auth.currentUser!.getIdToken();
      const successUrl = `${window.location.origin}/onboarding/step/1`;
      const price = lineItems[0].price;
      const session = await getCheckoutSessionURL(price, coupon, trial, successUrl, jwt);

      if (session.url) {
        flushLocalStorage();
        window.location.href = session.url;
      }
    } catch (err: any) {
      setError(err.message);
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  const flushLocalStorage = () => {
    localStorage.removeItem("plan");
    localStorage.removeItem("billingPeriod");
    localStorage.removeItem("currency");
    localStorage.removeItem("coupon");
    localStorage.removeItem("forcedPrice");
    localStorage.removeItem("hasSubmittedSurvey");
  };

  return {
    redirectToCheckout,
    checkoutIsLoading: isLoading,
    error,
  };
};

export default useCheckout;
