import { getZendeskToken } from "../../api/mailerfind/auth";

const login = async (jwt: string) => {
  const w = window as any;
  const { accessToken } = await getZendeskToken(jwt);
  w.zE("messenger", "loginUser", function (callback: any) {
    callback(accessToken);
  });
};

const logout = () => {
  const w = window as any;
  w.zE("messenger", "logoutUser");
};

const show = () => {
  const w = window as any;
  w.zE("messenger", "show");
};

const hide = () => {
  const w = window as any;
  w.zE("messenger", "hide");
};

const open = () => {
  const w = window as any;
  w.zE("messenger", "open");
};

const close = () => {
  const w = window as any;
  w.zE("messenger", "close");
};

export { login, logout, show, hide, open, close };
