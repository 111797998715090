import React, { useEffect, useState, useRef, useContext } from "react";
import { getUserFollowers, getUserInfo, getUsersInfo } from "../../api/mailerfind/users";
import { getAnalysis as getLocalAnalysis } from "../../lib/daos/analysisDAO";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GearFill, PencilSquare } from "react-bootstrap-icons";
import { analysisIsCloud, convertProspect, isValidInstagramURL } from "../../utils/helpers";
import { UserContext } from "../../contexts/UserContext";
import { auth } from "../../lib/firebase/firebase";
import { DEFAULT_ANALYSIS_NAME, MODE, SPEED, STATUS } from "../../utils/constants";
import { getAnalysisById as getCloudAnalysis, updateAnalysis as updateCloudAnalysis } from "../../lib/firebase/analysis";
import {
  addProspectsToAnalysis as saveLocalProspects,
  getAllProspectsInAnalysis as getLocalProspects,
  searchProspects as searchLocalProspects,
  searchProspects,
} from "../../lib/daos/prospectsDAO";
import DataTable from "./DataTable";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Progress from "./Progress";
import AccountSelect from "./AccountSelect";
import SelectMode from "./SelectMode";
import PostSelect from "./PostSelect";
import HashtagSelect from "./HashtagSelect";
import LocationSelect from "./LocationSelect";
import { getMediaCommenters, getMediaInfo, getMediaLikers } from "../../api/mailerfind/posts";
import {
  getProspectsWithPagination as getCloudProspects,
  searchProspects as searchCloudProspects,
  getProspectByUsernameInAnalysis,
} from "../../lib/firebase/prospects";
import { getHashtagInfo, getHashtagMedia } from "../../api/mailerfind/hashtags";
import { getLocationInfo, getLocationMedia } from "../../api/mailerfind/locations";
import { debounce } from "lodash";
import toast from "react-hot-toast";
import PremiumModal from "../../components/PremiumModal";
import OutOfCreditsModal from "./OutOfCreditsModal";
import { startAnalysis, pauseAnalysis } from "../../api/mailerfind/analysis";

const REFRESH_INTERVAL = 3000;

function DataExtractor(props) {
  // User
  const { user } = useContext(UserContext);
  const { t } = useTranslation("Analysis.index");

  // Required Analysis Data
  let { id: paramsId } = useParams();
  let id = props.id || paramsId;
  const interval = useRef(null);
  const [analysis, setAnalysis] = useState(null);
  const [prospects, setProspects] = useState([]);
  const [totalUsersEntered, setTotalUsersEntered] = useState(0);

  // Other Data for the analysis
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(true);
  const componentMounted = useRef(true);

  // Hooks
  const hasToPause = useRef(false);

  // Auto Retry addon
  const timesRetried = useRef(0);
  const hasToCancelRetry = useRef(false);
  const retryTimeout = useRef(null);

  // Others
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showOutOfCreditsModal, setShowOutOfCreditsModal] = useState(false);

  const handleModeChange = (mode) => {
    setAnalysis((prevState) => ({ ...prevState, mode: mode }));
  };

  /**
   * @description - Use effect, will load the analysis from database
   * This will be called when the component mounts
   */
  useEffect(() => {
    if (!id) return;
    loadAnalysis(id);
  }, [id]);

  useEffect(() => {
    if (!id || !analysis || prospects.length >= 20) return;
    loadProspects();
  }, [analysis]);

  useEffect(() => {
    const totalUsersEntered = getTotalUsersEntered();
    setTotalUsersEntered(totalUsersEntered);
    smartAnalysisRename();
  }, [analysis?.selectedItem]);

  useEffect(() => {
    componentMounted.current = true;
    return () => {
      // This code runs when component is unmounted
      componentMounted.current = false; // Set it to false when we leave the page
      clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    if (loading || !analysis) return;
    if (analysis.isCloud && analysis.version < 3) updateCloudAnalysis(user?.firebaseUser?.uid, analysis.id, analysis);
    // else updateLocalAnalysis(Number(id), analysis);
    props.onChange && props.onChange(analysis);
  }, [analysis]);

  useEffect(() => {
    if (loading || !analysis) return;
    if (analysis.isCloud && analysis.version >= 3) {
      const changes = {
        name: analysis.name,
        mode: analysis.mode,
        selectedItem: analysis.selectedItem,
      };
      updateCloudAnalysis(user?.firebaseUser?.uid, analysis.id, changes);
    }
    props.onChange && props.onChange(analysis);
  }, [analysis?.selectedItem, analysis?.mode, analysis?.name]);

  useEffect(() => {
    if (!analysis?.status || loading) return;
    if (analysis?.status === STATUS.ERROR) {
      // The user has to upgrade to premium
      if (errorMessage.includes("sample credits")) {
        setShowOutOfCreditsModal(true);
      }

      if (hasToCancelRetry.current) {
        hasToCancelRetry.current = false;
        return;
      }

      // Do not retry if credit limit exceeded
      if (errorMessage.includes("exceeded")) return;
      if (errorMessage.includes("subscription")) return;
      if (errorMessage.includes("trial")) return;
      if (errorMessage.includes("multiple devices")) return;
      if (errorMessage.includes("verify")) return;

      if (timesRetried.current < 3) {
        handleRetry();
      } else {
        setErrorMessage((prevState) => prevState + " Please try again later.");
        timesRetried.current = 0;
      }
    }
  }, [analysis?.status]);

  useEffect(() => {
    if (!analysis) return;
    if (analysis.version < 3) return; // Only for new analyses

    if (analysis.status === STATUS.RUNNING || analysis.status === STATUS.STARTING) {
      if (interval.current) return; // If interval is already set, do not set it again
      interval.current = setInterval(() => {
        loadAnalysis(id);
      }, REFRESH_INTERVAL);
    }

    if (analysis.status === STATUS.COMPLETED || analysis.status === STATUS.PAUSED || analysis.status === STATUS.ERROR) {
      if (interval.current) clearInterval(interval.current);
      interval.current = null;
    }
  }, [analysis?.status]);

  const getProspects = async (analysis) => {
    if (analysis?.isCloud) {
      return (await getCloudProspects(user?.firebaseUser?.uid, analysis.id, null)).prospects;
    } else {
      return await getLocalProspects(Number(analysis.id));
    }
  };

  const saveProspects = async (analysisId, prospects) => {
    if (analysis.isCloud) {
      // return await saveCloudProspects(analysis.id, prospects);
      // Prospects are added to the analysis in the cloud (in the backend)
    } else {
      prospects = prospects.map((prospect) => ({
        ...prospect,
        createdAt: new Date(),
        unsubscribed: false,
        emails_sent: [],
      }));
      return await saveLocalProspects(Number(analysisId), prospects);
    }
  };

  const loadProspects = async () => {
    const prospects = await getProspects(analysis);
    setProspects(prospects);
  };

  const loadAnalysis = async (analysisId) => {
    setLoading(true);

    let analysis;
    if (!analysisIsCloud(analysisId)) analysis = await getLocalAnalysis(null, Number(analysisId));
    else analysis = await getCloudAnalysis(user?.firebaseUser?.uid, analysisId);
    setAnalysis(analysis);

    const runningStatuses = [STATUS.RUNNING, STATUS.PAUSING, STATUS.STARTING, STATUS.RETRYING];
    analysis.version < 3 &&
      setAnalysis((prevState) => ({
        ...prevState,
        status: runningStatuses.includes(analysis.status) ? STATUS.PAUSED : analysis.status,
      }));

    if (analysis.status === STATUS.ERROR) {
      hasToCancelRetry.current = true;
    }

    setLoading(false);
  };

  const smartAnalysisRename = async () => {
    if (!analysis) return;
    if (analysis.name !== DEFAULT_ANALYSIS_NAME) return; // If the analysis has a custom name, do not rename it
    if (!analysis.selectedItem) return; // If there is no selected item, do not rename it
    switch (analysis.mode) {
      case MODE.FOLLOWERS:
        setAnalysis((prevState) => ({
          ...prevState,
          name: t("naming.followers", { username: analysis.selectedItem.username }),
        }));
        break;
      case MODE.LIKERS:
        setAnalysis((prevState) => ({
          ...prevState,
          name: t("naming.likers", { username: analysis.selectedItem.user.username }),
        }));
        break;
      case MODE.COMMENTERS:
        setAnalysis((prevState) => ({
          ...prevState,
          name: t("naming.commenters", { username: analysis.selectedItem.user.username }),
        }));
        break;
      case MODE.HASHTAG:
        setAnalysis((prevState) => ({
          ...prevState,
          name: `#${analysis.selectedItem.name}`,
        }));
        break;
      case MODE.LOCATION:
        setAnalysis((prevState) => ({
          ...prevState,
          name: `${analysis.selectedItem.name}`,
        }));
        break;
      default:
        break;
    }
  };

  const handleAccountSelect = async (e) => {
    try {
      const jwt = await auth.currentUser.getIdToken();
      const user = await getUserInfo(e.value, jwt);
      setAnalysis((prevState) => ({ ...prevState, selectedItem: user }));
    } catch (err) {
      toast.error(err.message || t("errorOccurred"));
    }
  };

  const handlePostSelect = async (e) => {
    try {
      const postUrl = e.target.value;
      if (!postUrl) return;
      if (!isValidInstagramURL(postUrl)) throw new Error("Invalid post url");
      const shortcode = postUrl.split("/")[4];
      if (!shortcode) throw new Error("Invalid post url");
      const jwt = await auth.currentUser.getIdToken();
      const { media } = await getMediaInfo(shortcode, jwt);
      setAnalysis((prevState) => ({ ...prevState, selectedItem: media }));
    } catch (err) {
      toast.error(err.message);
    }
  };

  const debouncedHandlePostSelect = debounce(handlePostSelect, 1000); // 500 ms de espera

  const handleHashtagSelect = async (e) => {
    try {
      const jwt = await auth.currentUser.getIdToken();
      const response = await getHashtagInfo(e.value, jwt);
      const newHashtag = {
        name: response.hashtag.name,
        id: response.hashtag.id,
        formatted_media_count: response.hashtag.formatted_media_count,
      };
      setAnalysis((prevState) => ({ ...prevState, selectedItem: newHashtag }));
    } catch (err) {
      toast.error(err.message || t("couldNotFindHashtag"));
    }
  };

  const handleLocationSelect = async (e) => {
    try {
      const jwt = await auth.currentUser.getIdToken();
      const response = await getLocationInfo(e.value, jwt);
      setAnalysis((prevState) => ({
        ...prevState,
        selectedItem: response.location,
      }));
    } catch (err) {
      toast.error(err.message || t("couldNotFindLocation"));
    }
  };

  const handleError = (msg, forcePause = false) => {
    setErrorMessage(msg || t("errorOccurred"));
    forcePause ? handleForcePause() : handlePause();
  };

  const handleRetry = () => {
    setAnalysis((prevState) => ({ ...prevState, status: STATUS.RETRYING }));
    timesRetried.current++;
    retryTimeout.current = setTimeout(() => {
      handleStart();
    }, 6000 * timesRetried.current + 1);
  };

  const handleCancelRetry = () => {
    setAnalysis((prevState) => ({ ...prevState, status: STATUS.PAUSED }));
    timesRetried.current = 0;
    hasToCancelRetry.current = true;
    clearTimeout(retryTimeout.current);
  };

  const handlePause = async () => {
    const jwt = await auth.currentUser.getIdToken();
    setAnalysis((prevState) => ({ ...prevState, status: STATUS.PAUSING }));
    if (analysis.version >= 3) {
      await pauseAnalysis(id, jwt);
      await loadAnalysis(id);
    }
    hasToPause.current = true;
  };

  const handleForcePause = async () => {
    setAnalysis((prevState) => ({ ...prevState, status: STATUS.ERROR }));
  };

  const handleStart = async () => {
    setAnalysis((prevState) => ({ ...prevState, status: STATUS.STARTING }));
    setErrorMessage("");

    try {
      const jwt = await auth.currentUser.getIdToken();
      const result = await startAnalysis(id, jwt);
      if (result.status !== "Ok") return;
      if (analysis.version <= 2) {
        setAnalysis((prevState) => ({ ...prevState, status: STATUS.RUNNING }));
        await analyzeFollowers();
      }
    } catch (err) {
      handleError(err.message || t("errorOccurred"), true);
    }
  };

  const getTotalUsersEntered = () => {
    const modeMapping = {
      [MODE.FOLLOWERS]: analysis?.selectedItem?.follower_count || analysis?.selectedItem?.edge_followed_by?.count,
      [MODE.LIKERS]: analysis?.selectedItem?.like_count,
      [MODE.COMMENTERS]: analysis?.selectedItem?.comment_count,
      [MODE.HASHTAG]: -1,
      [MODE.LOCATION]: -1,
    };

    return modeMapping[analysis?.mode] || 0;
  };

  const getNextBatch = async (cursor, page = 0) => {
    const jwt = await auth.currentUser.getIdToken();

    const getFollowersData = async () => {
      const { followers } = await getUserFollowers(analysis.selectedItem.id, cursor, analysis.selectedItem.is_verified, jwt);
      let users = followers[0];
      cursor = followers[1];
      return {
        users: users.map(({ id, ...rest }) => rest),
        cursor: cursor,
        page,
      };
    };

    const getLikersData = async () => {
      let [users, newCursor] = await getMediaLikers(analysis.selectedItem.id, cursor, jwt);
      users = users.map((user) => {
        delete user.reel;
        return user;
      });
      return { users, cursor: newCursor, page };
    };

    const getCommentersData = async () => {
      let [comments, newCursor] = await getMediaCommenters(analysis.selectedItem.id, cursor, jwt);
      const users = comments.map((comment) => {
        return comment.user;
      });
      const uniqueUsers = await getUniqueUsers(users);
      return { users: uniqueUsers, cursor: newCursor, page };
    };

    const getHashtagData = async () => {
      const jwt = await auth.currentUser.getIdToken();
      let response = await getHashtagMedia(analysis.selectedItem.name, cursor, page, jwt);
      const sections = response[0];
      cursor = response[1];
      page++;

      const users = sections.flatMap((mediaGrid) => mediaGrid.layout_content.medias.map((medias) => medias.media.user));

      const uniqueUsers = await getUniqueUsers(users);

      return {
        users: uniqueUsers,
        cursor,
        page,
      };
    };

    const getLocationData = async () => {
      const jwt = await auth.currentUser.getIdToken();
      let response = await getLocationMedia(analysis?.selectedItem?.pk, cursor, page, jwt);

      const sections = response[0];
      cursor = response[1];
      page++;

      const users = sections.flatMap((mediaGrid) => mediaGrid.layout_content.medias.map((medias) => medias.media.user));

      const uniqueUsers = await getUniqueUsers(users);

      return {
        users: uniqueUsers,
        cursor,
        page,
      };
    };

    const getUniqueUsers = async (users) => {
      const promises = users.map(async (_user) => {
        try {
          const prospect = analysis.isCloud
            ? await getProspectByUsernameInAnalysis(user?.firebaseUser?.uid, _user.username, id)
            : await searchProspects(Number(id), _user.username, 0, 1);

          return prospect.length === 0 ? _user : null;
        } catch (error) {
          // console.error(`Error fetching prospect for user ${_user.username}:`, error);
          return _user; // En caso de error, devolvemos el usuario para analizarlo
        }
      });

      const results = await Promise.all(promises);
      return results.filter(Boolean); // Filtramos resultados no nulos
    };

    switch (analysis.mode) {
      case MODE.FOLLOWERS:
        return getFollowersData();
      case MODE.LIKERS:
        return getLikersData();
      case MODE.COMMENTERS:
        return getCommentersData();
      case MODE.HASHTAG:
        return getHashtagData();
      case MODE.LOCATION:
        return getLocationData();
      default:
        return { users: [], cursor: null };
    }
  };

  /*
   * @description - This function will return true if there are more users to analyze
   * for hashtag and location, as we dont have the exact number of users, we will use the cursor
   * @param {string} nextCursor - The next cursor to use in the request
   * @returns {boolean} - True if there are more users to analyze, false otherwise
   * */
  const thereAreMoreUsers = (prospectsCount, nextCursor) => {
    if (analysis.mode === MODE.HASHTAG || analysis.mode === MODE.LOCATION || analysis.mode === MODE.LIKERS) {
      if (!nextCursor && prospectsCount > 0) return false;
      return true;
    }
    return prospectsCount < getTotalUsersEntered();
  };

  const analyzeFollowers = async () => {
    let prospectsCount = analysis.prospectsCount || 0;
    let businessAccountsCount = analysis.businessAccountsCount || 0;
    let emailsFoundCount = analysis.emailsFoundCount || 0;
    let publicAccountsCount = analysis.publicAccountsCount || 0;
    let privateAccountsCount = analysis.privateAccountsCount || 0;
    let creditsUsed = analysis.creditsUsed || 0;
    let phoneNumbersFoundCount = analysis.phoneNumbersFoundCount || 0;
    let websitesFoundCount = analysis.websitesFoundCount || 0;
    let cursor = analysis.cursor;
    let page = analysis.page;

    while (thereAreMoreUsers(prospectsCount, cursor)) {
      const { users, cursor: nextCursor, page: nextPage } = await getNextBatch(cursor, page);
      const publicAccounts = users.filter((user) => !user.is_private).map((user) => ({ username: user.username, pk: user.pk }));
      const privateAccounts = users.filter((user) => user.is_private).map((user) => ({ username: user.username, pk: user.pk }));
      const batchSize = user?.firebaseUser?.analysis?.batchSize || 5;

      cursor = nextCursor;
      page = nextPage;

      if (users.length === 0) {
        return handleCompleted();
      }

      const batches = [];

      for (let i = 0; i < publicAccounts.length; i += batchSize) {
        batches.push(publicAccounts.slice(i, i + batchSize));
      }

      for (const batch of batches) {
        const jwt = await auth.currentUser.getIdToken();
        const { users } = await getUsersInfo(batch, analysis.isCloud ? id : null, jwt);
        const publicUsers = users.map(convertProspect);

        // Save prospects to database
        await saveProspects(id, publicUsers);

        // The rest of the users are private
        prospectsCount += publicUsers.length;
        businessAccountsCount += publicUsers.filter((user) => user.is_business).length;
        emailsFoundCount += publicUsers.filter((user) => user.email).length;
        phoneNumbersFoundCount += publicUsers.filter((user) => user.phone_number).length;
        websitesFoundCount += publicUsers.filter((user) => user.website).length;
        creditsUsed += publicUsers.length;
        publicAccountsCount += publicUsers.length;
        privateAccountsCount = prospectsCount - publicAccountsCount;
        timesRetried.current = 0;

        setAnalysis((prevState) => ({
          ...prevState,
          prospectsCount,
          businessAccountsCount,
          emailsFoundCount,
          phoneNumbersFoundCount,
          websitesFoundCount,
          publicAccountsCount,
          privateAccountsCount,
          creditsUsed,
          cursor: nextCursor,
          page: nextPage,
        }));

        if (!componentMounted.current) return;

        if (hasToPause.current === true) {
          setAnalysis((prevState) => ({
            ...prevState,
            status: STATUS.PAUSED,
          }));
          hasToPause.current = false;
          return;
        }

        const speed = user?.firebaseUser?.analysis?.speed || SPEED.NORMAL;
        if (speed === SPEED.SLOW) await new Promise((resolve) => setTimeout(resolve, 6000));
        if (speed === SPEED.NORMAL) await new Promise((resolve) => setTimeout(resolve, 5000));
        if (speed === SPEED.FAST) await new Promise((resolve) => setTimeout(resolve, 3000));
        if (speed === SPEED.ULTRA_FAST) await new Promise((resolve) => setTimeout(resolve, 100));
      }

      prospectsCount += privateAccounts.length;

      // Refresh prospects
      const prospects = await getProspects(analysis);
      setProspects(prospects);

      setAnalysis((prevState) => ({
        ...prevState,
        prospectsCount,
      }));
    }

    handleCompleted();
  };

  const handleCompleted = () => {
    setAnalysis((prevState) => ({ ...prevState, status: STATUS.COMPLETED }));
  };

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    if (!value) {
      setProspects(await getProspects(analysis));
    }
    if (analysisIsCloud(analysis.id)) {
      const prospects = await searchCloudProspects(user?.firebaseUser?.uid, analysis.id, value);
      setProspects(prospects);
    } else {
      const prospects = await searchLocalProspects(Number(id), value, 0, 100);
      setProspects(prospects);
    }
  };

  const handleBack = () => {
    setAnalysis((prevState) => ({
      ...prevState,
      mode: null,
      selectedItem: null,
    }));
  };

  const handleRename = () => {
    const name = prompt(t("enterNewName"), analysis.name);
    if (!name) return;
    setAnalysis((prevState) => ({ ...prevState, name }));
    toast.success(t("analysisRenamed"));
  };

  if (!analysis) return <Loading />;

  return (
    <div>
      {!props.focusMode && (
        <Header
          title={t("accountsAnalyzer")}
          subtitle={analysis.name}
          breadCrumbItems={[
            { title: t("dashboard"), href: "/" },
            { title: t("analysisManager"), href: "/analysis-manager" },
            { title: analysis.name, active: true },
          ]}
        >
          <div className="dropdown">
            <button
              className="btn btn-white dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <GearFill className="mr-2" /> {t("options")}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <span className="dropdown-item" onClick={handleRename} style={{ cursor: "pointer" }}>
                <PencilSquare className="mr-1" />
                {t("rename")}
              </span>
            </div>
          </div>
        </Header>
      )}

      {!props.focusMode && (
        <>
          {analysis.version === 3 && analysis.status === "NOT_STARTED" && (
            <div className="alert alert-soft-success alert-dismissible fade show" role="alert">
              <h4 class="alert-heading">New feature! 🚀</h4>
              <strong></strong> Analyze with your computer turned off! No need to keep your computer on or the Mailerfind tab open anymore.
              With our latest update, you can scrape data seamlessly even when your computer is off.
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          )}

          {!analysis.mode && <SelectMode onSelect={handleModeChange} />}
          {analysis.mode === MODE.FOLLOWERS && (
            <AccountSelect
              onSelect={handleAccountSelect}
              selectedAccount={analysis.selectedItem}
              status={analysis.status}
              handleBack={handleBack}
            />
          )}
          {analysis.mode === MODE.LIKERS && (
            <PostSelect
              onSelect={debouncedHandlePostSelect}
              selectedPost={analysis.selectedItem}
              status={analysis.status}
              mode="likers"
              handleBack={handleBack}
            />
          )}
          {analysis.mode === MODE.COMMENTERS && (
            <PostSelect
              onSelect={debouncedHandlePostSelect}
              selectedPost={analysis.selectedItem}
              status={analysis.status}
              mode="commenters"
              handleBack={handleBack}
            />
          )}
          {analysis.mode === MODE.HASHTAG && (
            <HashtagSelect
              onSelect={handleHashtagSelect}
              selectedHashtag={analysis.selectedItem}
              status={analysis.status}
              handleBack={handleBack}
            />
          )}
          {analysis.mode === MODE.LOCATION && (
            <LocationSelect
              onSelect={handleLocationSelect}
              selectedHashtag={analysis.selectedItem}
              status={analysis.status}
              handleBack={handleBack}
            />
          )}
        </>
      )}

      <Progress
        analysis={analysis}
        handleStart={handleStart}
        handlePause={handlePause}
        handleCancelRetry={handleCancelRetry}
        totalUsersEntered={totalUsersEntered}
        errorMessage={analysis.errorMessage || errorMessage}
        focusMode={props.focusMode}
        gradient={user.firebaseUser.analysis.speed === SPEED.ULTRA_FAST && analysis.status !== STATUS.COMPLETED}
      />

      {!props.focusMode && (
        <DataTable
          users={prospects || []}
          analysis={analysis}
          analysisId={Number(id)}
          exportName={analysis.name}
          handleSearchChange={handleSearchChange}
        />
      )}

      <PremiumModal show={showPremiumModal} setShow={setShowPremiumModal} title={t("updatePlan")} />
      <OutOfCreditsModal show={showOutOfCreditsModal} setShow={setShowOutOfCreditsModal} />
    </div>
  );
}

export default DataExtractor;
