import React, { useContext, useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import Confetti from "react-confetti";
import { getAnalysisById as getCloudAnalysis } from "../../lib/firebase/analysis";
import { getAnalysis as getLocalAnalysis } from "../../lib/daos/analysisDAO";
import { UserContext } from "../../contexts/UserContext";
import toast from "react-hot-toast";
import Loading from "../../components/Loading";
import { Wizard } from "../../types/WizardTypes";
import { analysisIsCloud } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

interface Props {
  wizard: Wizard;
}

function Step5({ wizard }: Props) {
  const CURRENT_STEP = 5;
  const { user } = useContext(UserContext) as any;
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null) as any;
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  const { t } = useTranslation("Wizard.Step5");

  useEffect(() => {
    const { innerWidth: width, innerHeight: height } = window;
    setDimensions({
      width,
      height,
    });
  }, []);

  const fetchAnalysis = async (analysisId: string | number) => {
    try {
      setLoading(true);
      let analysis = null;
      if (typeof analysisId === "number") {
        analysis = await getLocalAnalysis(user.firebaseUser.uid, analysisId);
      } else {
        analysis = await getCloudAnalysis(user.firebaseUser.uid, analysisId);
      }
      setAnalysis(analysis);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user || !wizard.analysisId) return;
    fetchAnalysis(wizard.analysisId);
  }, [user, wizard.analysisId]);

  if (!analysis) return <Loading />;

  const items = [
    t("analyzedAccount", { username: analysis.selectedItem.username }),
    t("foundEmailsPhones", { emailsCount: analysis.emailsFoundCount, phonesCount: analysis.phoneNumbersFoundCount }),
    t("connectedEmail"),
    t("templateReady"),
  ];

  return (
    <>
      <Confetti width={dimensions.width} height={dimensions.height} numberOfPieces={500} recycle={false} />
      <Wrapper title={t("goodJob")} step={CURRENT_STEP}>
        <ul className="list-checked list-checked-success">
          {items.map((item, index) => (
            <li key={index} className="list-checked-item reveal-item" style={{ animationDelay: `${index * 0.9}s` }}>
              {item}
            </li>
          ))}
        </ul>
      </Wrapper>
    </>
  );
}

export default Step5;
