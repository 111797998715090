import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { auth } from "../../lib/firebase/firebase";
import { test } from "../../api/mailerfind/email";
import classNames from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BoxArrowUpRight, EyeFill, EyeSlashFill, QuestionCircleFill } from "react-bootstrap-icons";
import RichTextEditor from "../../components/RichTextEditor";
import _ from "lodash";
import { useTranslation } from "react-i18next";

function Form({ id, updateSender, sender, setEmailAddress, loading, handleSubmit, saved, setSaved }: any) {
  const [fieldErrors, setFieldErrors] = useState({}) as any;
  const [testIsLoading, setTestIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation("Sender.Form");

  const testSMTPConnection = async () => {
    if (!id) {
      toast.error(t("saveSettingsFirst"));
      return;
    }
    setTestIsLoading(true);
    const jwt = (await auth.currentUser?.getIdToken()) as string;
    await updateSender(sender.senderId || sender.id);
    try {
      const response = await test(sender.senderId || sender.id, jwt);
      toast.success(response.message);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setTestIsLoading(false);
    }
  };

  const handleChange = useCallback(
    (e: any) => {
      let { name, value } = e.target;

      setSaved(false);

      if (name === "smtp.port") {
        value = parseInt(value, 10);
      }

      if (name === "delayBetweenEmails" || name === "messagesPerDay") {
        value = parseInt(value, 10);
      }

      setEmailAddress((prev: any) => {
        const nextState = _.cloneDeep(prev);
        _.set(nextState, name, value);
        return nextState;
      });

      setFieldErrors((errors: any) => ({
        ...errors,
        [name]: !value,
      }));
    },
    [sender]
  );

  const handleSignatureChange = useCallback(
    (value: any) => {
      setSaved(false);
      setEmailAddress((prev: any) => ({
        ...prev,
        signature: value,
      }));
    },
    [sender.signature]
  );

  return (
    <div className="card">
      <div className="card-header card-header-content-between">
        <h4 className="card-header-title">{t("settings")}</h4>
      </div>
      <div className="card-body pb-5">
        <div className="row">
          <div className={"col-lg-12"}>
            <div className="form-group">
              <label htmlFor="emailAddress">{t("senderName")}</label>
              <input
                type="text"
                className={classNames("form-control", { "is-invalid": fieldErrors["name"] })}
                id="senderName"
                disabled={loading}
                name="name"
                onChange={handleChange}
                value={sender.name}
                placeholder={t("placeholder.senderName")}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="messagesPerDay">
                {t("messagesPerDay")}
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => <Tooltip {...props}>{t("tooltip.messagesPerDay")}</Tooltip>}
                  placement="bottom"
                >
                  <QuestionCircleFill size="14" className="ml-1 mb-1" />
                </OverlayTrigger>
              </label>
              <input
                type="number"
                name="messagesPerDay"
                disabled={loading}
                onChange={handleChange}
                className={classNames("form-control", { "is-invalid": fieldErrors["messagesPerDay"] })}
                id="messagesPerDay"
                value={sender.messagesPerDay}
                min={10}
                max={10000}
                autoComplete="off"
                placeholder={t("placeholder.messagesPerDay")}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="delayBetweenEmails">
                {t("delayBetweenEmails")}
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => <Tooltip {...props}>{t("tooltip.delayBetweenEmails")}</Tooltip>}
                  placement="bottom"
                >
                  <QuestionCircleFill size="14" className="ml-1 mb-1" />
                </OverlayTrigger>
              </label>
              <div className="input-group">
                <input
                  type="number"
                  name="delayBetweenEmails"
                  onChange={handleChange}
                  className={classNames("form-control", { "is-invalid": fieldErrors["delayBetweenEmails"] })}
                  id="delayBetweenEmails"
                  value={sender.delayBetweenEmails}
                  placeholder={t("placeholder.delayBetweenEmails")}
                  disabled={loading}
                  min={1}
                  max={10}
                />
                <div className="input-group-append">
                  <div className="input-group-text">{t("seconds")}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="emailAddress">{t("emailAddress")}</label>
              <input
                type="text"
                className={classNames("form-control", { "is-invalid": fieldErrors["email"] })}
                id="emailAddress"
                name="email"
                disabled={sender.provider === "google" || loading}
                onChange={handleChange}
                value={sender.email}
                placeholder={t("placeholder.emailAddress")}
              />
            </div>
          </div>
          {sender.provider === "sendgrid" && (
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="apiKey">
                  <a href="https://app.sendgrid.com/settings/api_keys" target="_blank" rel="noreferrer">
                    <span className="align-middle">{t("sendgridAPIKey")}</span> <BoxArrowUpRight size={10} />
                  </a>
                </label>
                <input
                  type="password"
                  disabled={loading}
                  className={classNames("form-control", { "is-invalid": fieldErrors["apiKey"] })}
                  id="apiKey"
                  name="apiKey"
                  onChange={handleChange}
                  value={sender.apiKey}
                  placeholder={t("placeholder.sendgridAPIKey")}
                />
              </div>
            </div>
          )}
        </div>
        {sender.provider === "smtp" && (
          <>
            <label htmlFor="smtp.host">{t("smtpSettings")}</label>
            <div className="row border rounded mx-0 mb-5 p-4">
              <div className="col-8">
                <div className="form-group">
                  <label htmlFor="smtp.host">{t("host")}</label>
                  <input
                    type="text"
                    autoComplete="off"
                    disabled={loading}
                    className={classNames("form-control", { "is-invalid": fieldErrors["smtp.host"] })}
                    id="smtp.host"
                    name="smtp.host"
                    defaultValue={sender.email.split("@")[1]}
                    onChange={handleChange}
                    value={sender.smtp?.host}
                    placeholder={t("placeholder.host")}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="smtp.port">{t("port")}</label>
                  <select
                    className={classNames("form-control", { "is-invalid": fieldErrors["smtp.port"] })}
                    id="smtp.port"
                    autoComplete="off"
                    disabled={loading}
                    name="smtp.port"
                    onChange={handleChange}
                    defaultValue={587}
                    value={sender?.smtp?.port}
                  >
                    <option value={-1} disabled selected>
                      {t("select")}
                    </option>
                    <option value={25}>{t("portOptions.notSecure")}</option>
                    <option value={465}>{t("portOptions.sslTls")}</option>
                    <option value={587}>{t("portOptions.starttls")}</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="smtp.auth.username">{t("username")}</label>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      className={classNames("form-control", { "is-invalid": fieldErrors["smtp.auth.username"] })}
                      id="smtp.auth.username"
                      name="smtp.auth.username"
                      autoComplete="off"
                      disabled={loading}
                      onChange={handleChange}
                      defaultValue={sender.email}
                      value={sender.smtp?.auth?.username}
                      placeholder={t("placeholder.username")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="smtp.auth.password">{t("password")}</label>
                  <div style={{ display: "flex" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      className={classNames("form-control", { "is-invalid": fieldErrors["smtp.auth.password"] })}
                      id="smtp.auth.password"
                      name="smtp.auth.password"
                      autoComplete="off"
                      disabled={loading}
                      onChange={handleChange}
                      value={sender.smtp?.auth?.password}
                      placeholder={t("placeholder.password")}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer", marginLeft: "-40px", marginTop: "8px" }}
                    >
                      {showPassword ? <EyeSlashFill size={20} /> : <EyeFill size={20} />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button type="button" className="btn btn-primary btn-sm" onClick={testSMTPConnection} disabled={testIsLoading}>
                  {testIsLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm align-middle mr-2" role="status" aria-hidden="true"></span>
                      <span className="m-0 p-0 align-middle">{t("testing")}...</span>
                    </>
                  ) : (
                    t("testSMTPConnection")
                  )}
                </button>
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-12">
            <div className="pb-2">
              <label>{t("signature")}</label>
              <RichTextEditor value={sender.signature} setValue={handleSignatureChange} />
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer d-flex justify-content-end align-items-center gap-3">
        <button type="button" className="btn btn-white mr-3">
          {t("cancel")}
        </button>
        <button className="btn btn-primary mr-2" onClick={handleSubmit} disabled={saved || loading}>
          {t("saveSettings")}
        </button>
      </div>
    </div>
  );
}

export default Form;