import React, { useEffect, useState, Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { UserProvider } from "./contexts/UserContext";
import Router from "./Router";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./lib/firebase/firebase";
import "./i18n";

import "react-loading-skeleton/dist/skeleton.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Loading from "./components/Loading";
import ReactPixel from "react-facebook-pixel";
import { getUser } from "./lib/firebase/user";
import { login as zendeskLogin } from "./lib/zendesk";
import { useTranslation } from "react-i18next";

import moment from "moment";
import "moment/locale/es";
import { getBrowserLanguage } from "./utils/helpers";

function App() {
  // Firebase User
  const [initialFirebaseUser, setInitialFirebaseUser] = useState(null);
  const [firebaseUserIsLoading, setFirebaseUserIsLoading] = useState(true);

  // Configure moment
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;

  useEffect(() => {
    moment.locale(currentLocale);
  }, [currentLocale]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (initialFirebaseUser) return;
        try {
          const { uid } = user;
          const firebaseUser = await getUser(uid);
          // i18n.changeLanguage(firebaseUser.preferences.language || "en");
          setInitialFirebaseUser({ ...user, ...firebaseUser });
        } catch (error) {
          console.log(error);
        } finally {
          await auth.currentUser.getIdToken(true);
          setFirebaseUserIsLoading(false);
        }
      } else {
        setInitialFirebaseUser(null);
        setFirebaseUserIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (initialFirebaseUser?.preferences?.language) {
      i18n.changeLanguage(initialFirebaseUser.preferences.language);
    } else {
      const browserLanguage = getBrowserLanguage();
      i18n.changeLanguage(browserLanguage);
    }
  }, [initialFirebaseUser]);

  useEffect(() => {
    ReactPixel.init("351950697186720", {}, { debug: true, autoConfig: false });
    ReactPixel.pageView();
  }, []);

  const loadZendesk = async () => {
    try {
      const jwt = await auth.currentUser.getIdToken();
      await zendeskLogin(jwt);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!initialFirebaseUser) return;
    // load any chat
    loadZendesk();
  }, [initialFirebaseUser]);

  if (firebaseUserIsLoading) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <UserProvider initialFirebaseUser={initialFirebaseUser}>
        <Router />
        <Toaster />
      </UserProvider>
    </Suspense>
  );
}

export default App;
